import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';

import HomePage from '@/views/dashboard/HomePage.vue';
import HomeEditPage from '@/views/dashboard/EditPage.vue';
import CalendarBookingPage from '@/views/dashboard/CalendarBookingPage.vue';
import StyleSheet from "@/views/StyleSheet.vue";

// Auth pages
import LoginPage from "@/views/auth/LoginPage.vue";
import ForgotPassword from "@/views/auth/password/ForgotPassword.vue";
import ResetPassword from "@/views/auth/password/ResetPassword.vue";
import VerificationCode from "@/views/auth/password/VerificationCode.vue";

// Branches
import BranchIndex from "@/views/branches/IndexPage.vue";
import BranchEdit from "@/views/branches/EditPage.vue";
import BranchCreate from "@/views/branches/CreatePage.vue";

// Branches - Companies
import CompaniesIndex from "@/views/branches/companies/IndexPage.vue";
import CompaniesEdit from "@/views/branches/companies/EditPage.vue";
import CompaniesBooking from "@/views/branches/companies/BookingInformation.vue";
import CompaniesCreate from "@/views/branches/companies/CreatePage.vue";

// Branches - Booking
import BookingIndex from "@/views/branches/bookings/IndexPage.vue";
import BookingCreate from "@/views/branches/bookings/CreatePage.vue";
import BookingEdit from "@/views/branches/bookings/EditPage.vue";

// Branches Blocked Dates
import BlockedDateIndex from "@/views/branches/blocked-dates/IndexPage.vue";
import BlockedDateEdit from "@/views/branches/blocked-dates/EditPage.vue";
import BlockedDateCreate from "@/views/branches/blocked-dates/CreatePage.vue";

import TimeSlot from "@/views/branches/time-slot/IndexPage.vue";

// Vouchers
import VouchersIndex from "@/views/vouchers/IndexPage.vue";
import VouchersShow from "@/views/vouchers/ShowPage.vue";
import VouchersCreate from "@/views/vouchers/CreatePage.vue";

// Contents - Packages
import PackagesIndex from "@/views/contents/packages/IndexPage.vue";
import PackagesShow from "@/views/contents/packages/ShowPage.vue";
import ScheduleShow from "@/views/contents/packages/SchedulePage.vue";

// Contents - Riders
import RidersIndex from "@/views/contents/riders/IndexPage.vue";
import RidersShow from "@/views/contents/riders/ShowPage.vue";
import RidersSchedule from "@/views/contents/riders/SchedulePage.vue";

// Contents - Illnesses/Conditions
import ConditionsIndex from "@/views/contents/conditions/IndexPage.vue";
import ConditionsShow from "@/views/contents/conditions/ShowPage.vue";
import ConditionsCreate from "@/views/contents/conditions/CreatePage.vue";

// Contents - HMO
import HmoIndex from "@/views/contents/hmo/IndexPage.vue";
import HmoShow from "@/views/contents/hmo/ShowPage.vue";
import HmoCreate from "@/views/contents/hmo/CreatePage.vue";

//Contents - Email Notifications
import EmailNotificationIndex from "@/views/contents/emailNotifications/IndexPage.vue";
import EmailNotificationShow from "@/views/contents/emailNotifications/ShowPage.vue";

//Contents - System Notifications
import SystemNotificationIndex from "@/views/contents/systemNotifications/IndexPage.vue";
import SystemNotificationShow from "@/views/contents/systemNotifications/ShowPage.vue";


//Contents - Pages
import PagesIndex from "@/views/contents/pages/IndexPage.vue"
import PatientShow from "@/views/contents/pages/ShowPage.vue"

// Accounts - Patients
import PatientsIndex from "@/views/accounts/patients/IndexPage.vue";
import PatientsShow from "@/views/accounts/patients/ShowPage.vue";
import PatientBookingIndex from "@/views/accounts/patients/bookings/IndexPage.vue";
import PatientBookingShow from "@/views/accounts/patients/bookings/ShowPage.vue";
import PatientMedicalRecordShow from "@/views/accounts/patients/medical-record/ShowPage.vue";
import PatientDependentIndex from "@/views/accounts/patients/dependents/IndexPage.vue";
import PatientDependentShow from "@/views/accounts/patients/dependents/ShowPage.vue";
// Accounts - Admins
import AdminsIndex from "@/views/accounts/admins/IndexPage.vue";
import AdminsShow from "@/views/accounts/admins/ShowPage.vue";
import AdminsCreate from "@/views/accounts/admins/CreatePage.vue";
// Accounts - Roles
import RolesIndex from "@/views/accounts/roles/IndexPage.vue";
import RolesShow from "@/views/accounts/roles/ShowPage.vue";
import RolesCreate from "@/views/accounts/roles/CreatePage.vue";
import RolesPermission from "@/views/accounts/roles/PermissionPage.vue";

// Reports
import ReportsIndex from "@/views/reports/IndexPage.vue";
import ReportsNewMember from "@/views/reports/NewMember.vue";
import ReportsActiveMember from "@/views/reports/ActiveMember.vue";
import ReportsTotalMember from "@/views/reports/TotalMember.vue";
import ReportsBooking from "@/views/reports/BookingPage.vue";
import IndividualBooking from "@/views/reports/IndividualBooking.vue";
import CorporateBooking from "@/views/reports/CorporateBooking.vue";

// Settings
import SettingsIndex from "@/views/settings/IndexPage.vue";
import SettingsView from "@/views/settings/ViewPage.vue";

// Activity Logs
import ActivityIndex from "@/views/activityLogs/IndexPage.vue";

// Profile
import ProfileIndex from "@/views/profile/IndexPage.vue";

// Notifications
import NotificationIndex from "@/views/notification/IndexPage.vue";

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        redirect: '/login',
        meta:{
            needsAuth: false
        }
    },
    {
        path: '/login',
        name: 'Login',
        component: LoginPage,
        meta:{
            needsAuth: false
        }
    },
    {
        path: '/forgot-password',
        name: 'ForgotPassword',
        component: ForgotPassword,
        meta:{
            needsAuth: false
        }
    },
    {
        path: '/reset-password',
        name: 'ResetPassword',
        component: ResetPassword,
        meta:{
            needsAuth: false
        }
    },
    {
        path: '/verification-code',
        name: 'VerificationCode',
        component: VerificationCode,
        meta:{
            needsAuth: false
        }
    },
    {
        path: '/home',
        name: 'Home',
        component: HomePage,
        meta:{
            needsAuth: true
        }
    },
    {
        path: '/home/booking/:date',
        name: 'CalendarBookingPage',
        component: CalendarBookingPage,
        meta:{
            needsAuth: true
        }
    },
    {
        path: '/home/view',
        name: 'HomeEditPage',
        component: HomeEditPage,
        meta:{
            needsAuth: true
        }
    },

    {
        path: '/branches',
        name: 'BranchIndex',
        component: BranchIndex,
        meta:{
            needsAuth: true
        }
    },
    {
        path: '/branches/archived',
        name: 'BranchIndexArchived',
        component: BranchIndex,
        meta:{
            needsAuth: true
        }
    },
    {
        path: '/branches/logs',
        name: 'BranchIndexLogs',
        component: BranchIndex,
        meta:{
            needsAuth: true
        }
    },
    {
        path: '/branches/edit/:id',
        name: 'BranchEdit',
        component: BranchEdit,
        meta:{
            needsAuth: true
        }
    },
    {
        path: '/branches/create',
        name: 'BranchCreate',
        component: BranchCreate,
        meta:{
            needsAuth: true
        }
    },

    {
        path: '/branches/:id/companies',
        name: 'CompaniesIndex',
        component: CompaniesIndex,
        meta:{
            needsAuth: true
        }
    },
    {
        path: '/branches/:id/companies/archived',
        name: 'CompaniesIndexArchived',
        component: CompaniesIndex,
        meta:{
            needsAuth: true
        }
    },
    {
        path: '/branches/:id/companies/logs',
        name: 'CompaniesIndexLogs',
        component: CompaniesIndex,
        meta:{
            needsAuth: true
        }
    },
    {
        path: '/branches/:id/companies/create',
        name: 'CompaniesCreate',
        component: CompaniesCreate,
        meta:{
            needsAuth: true
        }
    },
    {
        path: '/branches/:branchId/companies/edit/:companyId',
        name: 'CompaniesEdit',
        component: CompaniesEdit,
        meta:{
            needsAuth: true
        }
    },
    {
        path: '/branches/:branchId/companies/booking/:companyId',
        name: 'CompaniesBooking',
        component: CompaniesBooking,
        meta:{
            needsAuth: true
        }
    },
    {
        path: '/branches/:id/bookings',
        name: 'BookingIndex',
        component: BookingIndex,
        meta:{
            needsAuth: true
        }
    },
    {
        path: '/branches/:id/bookings/create',
        name: 'BookingCreate',
        component: BookingCreate,
        meta:{
            needsAuth: true
        }
    },
    {
        path: '/branches/:branchId/bookings/edit/:bookingId',
        name: 'BookingEdit',
        component: BookingEdit,
        meta:{
            needsAuth: true
        }
    },
    {
        path: '/branches/:branchId/bookings/edit/:bookingId/schedule-logs',
        name: 'BookingEditScheduleLogs',
        component: BookingEdit,
        meta:{
            needsAuth: true
        }
    },
    {
        path: '/branches/:id/blocked-dates',
        name: 'BlockedDateIndex',
        component: BlockedDateIndex,
        meta:{
            needsAuth: true
        }
    },
    {
        path: '/branches/:id/blocked-dates/archived',
        name: 'BlockedDateIndexArchived',
        component: BlockedDateIndex,
        meta:{
            needsAuth: true
        }
    },
    {
        path: '/branches/:id/blocked-dates/logs',
        name: 'BlockedDateIndexLogs',
        component: BlockedDateIndex,
        meta:{
            needsAuth: true
        }
    },
    {
        path: '/branches/:id/blocked-dates/create',
        name: 'BlockedDateCreate',
        component: BlockedDateCreate,
        meta:{
            needsAuth: true
        }
    },
    {
        path: '/branches/:id/blocked-dates/edit/:blockedDateId',
        name: 'BlockedDateEdit',
        component: BlockedDateEdit,
        meta:{
            needsAuth: true
        }
    },
    {
        path: '/branches/:id/time-slot/',
        name: 'TimeSlot',
        component: TimeSlot,
        meta:{
            needsAuth: true
        }
    },
    {
        path: '/vouchers',
        name: 'VouchersIndex',
        component: VouchersIndex,
        meta:{
            needsAuth: true
        }
    },
    {
        path: '/vouchers/create',
        name: 'VouchersCreate',
        component: VouchersCreate,
        meta:{
            needsAuth: true
        }
    },
    {
        path: '/vouchers/show/:id',
        name: 'VouchersShow',
        component: VouchersShow,
        meta:{
          needsAuth: true
        }
    },
    {
        path: '/contents/packages',
        name: 'PackagesIndex',
        component: PackagesIndex,
        meta:{
            needsAuth: true
        }
    },
    {
        path: '/contents/packages/show/:name',
        name: 'PackagesShow',
        component: PackagesShow,
        meta:{
            needsAuth: true
        }
    },
    {
        path: '/contents/packages/schedule/:name',
        name: 'ScheduleShow',
        component: ScheduleShow,
        meta:{
            needsAuth: true
        }
    },
    {
        path: '/contents/riders',
        name: 'RidersIndex',
        component: RidersIndex,
        meta:{
            needsAuth: true
        }
    },
    {
        path: '/contents/riders/show/:name',
        name: 'RidersShow',
        component: RidersShow,
        meta:{
            needsAuth: true
        }
    },
    {
        path: '/contents/riders/schedule/:name',
        name: 'RidersSchedule',
        component: RidersSchedule,
        meta:{
            needsAuth: true
        }
    },

    {
        path: '/contents/conditions',
        name: 'ConditionsIndex',
        component: ConditionsIndex,
        meta:{
            needsAuth: true
        }
    },
    {
        path: '/contents/conditions/archived',
        name: 'ConditionsIndexArchived',
        component: ConditionsIndex,
        meta:{
            needsAuth: true
        }
    },
    {
        path: '/contents/conditions/logs',
        name: 'ConditionsIndexLogs',
        component: ConditionsIndex,
        meta:{
            needsAuth: true
        }
    },
    {
        path: '/contents/conditions/edit/:id',
        name: 'ConditionsShow',
        component: ConditionsShow,
        meta:{
            needsAuth: true
        }
    },
    {
        path: '/contents/conditions/create',
        name: 'ConditionsCreate',
        component: ConditionsCreate,
        meta:{
            needsAuth: true
        }
    },


    {
        path: '/contents/hmo-accreditation',
        name: 'HmoIndex',
        component: HmoIndex,
        meta:{
            needsAuth: true
        }
    },
    {
        path: '/contents/hmo-accreditation/show/:id',
        name: 'HmoShow',
        component: HmoShow,
        meta:{
            needsAuth: true
        }
    },
    {
        path: '/contents/hmo-accreditation/create',
        name: 'HmoCreate',
        component: HmoCreate,
        meta:{
            needsAuth: true
        }
    },
    
    {
        path: '/contents/email-notification',
        name: 'EmailNotificationIndex',
        component: EmailNotificationIndex,
        meta:{
            needsAuth: true
        }
    },
    {
        path: '/contents/email-notification/edit/:id',
        name: 'EmailNotificationShow',
        component: EmailNotificationShow,
        meta:{
            needsAuth: true
        }
    },
    {
        path: '/contents/email-notification/edit/:id/logs',
        name: 'EmailNotificationLogs',
        component: EmailNotificationShow,
        meta:{
            needsAuth: true
        }
    },

    {
        path: '/contents/system-notification',
        name: 'SystemNotificationIndex',
        component: SystemNotificationIndex,
        meta:{
            needsAuth: true
        }
    },
    {
        path: '/contents/system-notification/edit/:id',
        name: 'SystemNotificationShow',
        component: SystemNotificationShow,
        meta:{
            needsAuth: true
        }
    },
    {
        path: '/contents/system-notification/edit/:id/logs',
        name: 'SystemNotificationLogs',
        component: SystemNotificationShow,
        meta:{
            needsAuth: true
        }
    },
    {
        path: '/contents/pages/index',
        name: 'PagesIndex',
        component: PagesIndex,
        meta:{
            needsAuth: true
        }
    },
    {
        path: '/contents/pages/show/:id',
        name: 'PatientShow',
        component: PatientShow,
        meta:{
            needsAuth: true
        }
    },
    {
        path: '/accounts/patients',
        name: 'PatientsIndex',
        component: PatientsIndex,
        meta:{
            needsAuth: true
        }
    },
    {
        path: '/accounts/patients/show/:id',
        name: 'PatientsShow',
        component: PatientsShow,
        meta:{
            needsAuth: true
        }
    },
    {
        path: '/accounts/patients/show/:id/dependents',
        name: 'PatientDependentIndex',
        component: PatientDependentIndex,
        meta:{
            needsAuth: true
        }
    },
    {
        path: '/accounts/patients/show/:id/dependents/:dependentId',
        name: 'PatientDependentShow',
        component: PatientDependentShow,
        meta:{
            needsAuth: true
        }
    },
    {
        path: '/accounts/patients/bookings/:id',
        name: 'PatientBookingIndex',
        component: PatientBookingIndex,
        meta:{
            needsAuth: true
        }
    },
    {
        path: '/accounts/patients/:id/bookings/show/:bookingId',
        name: 'PatientBookingShow',
        component: PatientBookingShow,
        meta:{
            needsAuth: true
        }
    },
    {
        path: '/accounts/patients/medical-record/show/:id',
        name: 'PatientMedicalRecordShow',
        component: PatientMedicalRecordShow,
        meta:{
            needsAuth: true
        }
    },

    {
        path: '/accounts/admins',
        name: 'AdminsIndex',
        component: AdminsIndex,
        meta:{
            needsAuth: true
        }
    },
    {
        path: '/accounts/admins/archived',
        name: 'AdminsIndexArchived',
        component: AdminsIndex,
        meta:{
            needsAuth: true
        }
    },
    {
        path: '/accounts/admins/logs',
        name: 'AdminsIndexLogs',
        component: AdminsIndex,
        meta:{
            needsAuth: true
        }
    },
    {
        path: '/accounts/admins/show/:id',
        name: 'AdminsShow',
        component: AdminsShow,
        meta:{
            needsAuth: true
        }
    },
    {
        path: '/accounts/admins/create',
        name: 'AdminsCreate',
        component: AdminsCreate,
        meta:{
            needsAuth: true
        }
    },

    {
        path: '/accounts/roles',
        name: 'RolesIndex',
        component: RolesIndex,
        meta:{
            needsAuth: true
        }
    },
    {
        path: '/accounts/roles/show/:id',
        name: 'RolesShow',
        component: RolesShow,
        meta:{
            needsAuth: true
        }
    },
    {
        path: '/accounts/roles/create',
        name: 'RolesCreate',
        component: RolesCreate,
        meta:{
            needsAuth: true
        }
    },
    {
        path: '/accounts/roles/permission/:roleId',
        name: 'RolesPermission',
        component: RolesPermission,
        meta:{
            needsAuth: true
        }
    },

    {
        path: '/reports',
        name: 'ReportsIndex',
        component: ReportsIndex,
        meta:{
            needsAuth: true
        }
    },
    {
        path: '/reports/new-member',
        name: 'ReportsNewMember',
        component: ReportsNewMember,
        meta:{
            needsAuth: true
        }
    },
    {
        path: '/reports/active-member',
        name: 'ReportsActiveMember',
        component: ReportsActiveMember,
        meta:{
            needsAuth: true
        }
    },
    {
        path: '/reports/total-member',
        name: 'ReportsTotalMember',
        component: ReportsTotalMember,
        meta:{
            needsAuth: true
        }
    },
    {
        path: '/reports/booking',
        name: 'ReportsBooking',
        component: ReportsBooking,
        meta:{
            needsAuth: true
        }
    },
    {
        path: '/reports/individual-bookings',
        name: 'IndividualBooking',
        component: IndividualBooking,
        meta:{
            needsAuth: true
        }
    },
    {
        path: '/reports/corporate-bookings',
        name: 'CorporateBooking',
        component: CorporateBooking,
        meta:{
            needsAuth: true
        }
    },

    {
        path: '/settings',
        name: 'SettingsIndex',
        component: SettingsIndex,
        meta:{
            needsAuth: true
        }
    },
    {
        path: '/settings/edit/:id',
        name: 'SettingsView',
        component: SettingsView,
        meta:{
            needsAuth: true
        }
    },
    {
        path: '/settings/edit/:id/logs',
        name: 'SettingsLogs',
        component: SettingsView,
        meta:{
            needsAuth: true
        }
    },

    {
        path: '/activity-logs',
        name: 'ActivityIndex',
        component: ActivityIndex,
        meta:{
            needsAuth: true
        }
    },

    {
        path: '/profile/:id',
        name: 'ProfileIndex',
        component: ProfileIndex,
        meta:{
            needsAuth: true
        }
    },

    {
        path: '/notifications',
        name: 'NotificationIndex',
        component: NotificationIndex,
        meta:{
            needsAuth: true
        }
    },

    {
        path: '/stylesheet',
        name: 'Stylesheet',
        component: StyleSheet,
        meta:{
            needsAuth: true
        }
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
