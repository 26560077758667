import http from '@/axios'
import { IDropdown } from '@/interfaces/BaseInterface';

class DropdownData implements IDropdown {
    getCompanies() {
        return http.get(`dropdown/fetch-companies`);
    }
}

export default new DropdownData();
